import { json, LoaderFunctionArgs, type MetaFunction } from "@remix-run/node";
import { dehydrate, QueryClient } from "@tanstack/react-query";
import { FrontLayout } from "~/components/layout/front-layout";
import { getNavOutletQuery } from "~/features/pagebuilder/api/navigation-querries";
import { useGetFrontPage } from "~/features/pagebuilder/api/page-hooks";
import { getFrontPageQuery } from "~/features/pagebuilder/api/page-querries";
import { componentMap } from "~/features/pagebuilder/components/component-map";
import { RenderComponentsView } from "~/features/pagebuilder/components/render-view";
import { getSession } from "~/lib/session";

export const meta: MetaFunction = () => {
  return [
    { title: "Fizika sa Sonjom - Interaktivna platforma za učenje fizike" },
    {
      name: "description",
      content:
        "Fizika sa Sonjom je interaktivna aplikacija koja pomaže učenicima osnovnih i srednjih škola da poboljšaju svoje ocene iz fizike kroz zabavne lekcije, igre i eksperimente.",
    },
    {
      name: "keywords",
      content:
        "fizika, učenje fizike, interaktivne lekcije, eksperimenti, poboljšanje ocena, online obrazovanje, osnovna škola, srednja škola",
    },
    {
      property: "og:title",
      content: "Fizika sa Sonjom - Interaktivna platforma za učenje fizike",
    },
    {
      property: "og:description",
      content:
        "Pristupi interaktivnim lekcijama i eksperimentima uz garanciju poboljšanja ocena. Fizika više nije teška!",
    },
    { property: "og:image", content: "/images/og-image.jpg" }, // Replace with actual image path
    { property: "og:url", content: "https://www.fizikasasonjom.com" }, // Replace with your app's URL
    { property: "og:type", content: "website" },
    { name: "twitter:card", content: "summary_large_image" },
    {
      name: "twitter:title",
      content: "Fizika sa Sonjom - Učenje fizike kroz igru i eksperimente",
    },
    {
      name: "twitter:description",
      content:
        "Poboljšajte ocene iz fizike uz interaktivne lekcije i podršku profesora!",
    },
    { name: "twitter:image", content: "/images/twitter-image.jpg" }, // Replace with actual image path
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request.headers.get("Cookie"));
  const access_token = session.get("access_token") || "";
  const queryClient = new QueryClient();
  await Promise.all([
    queryClient.prefetchQuery(getNavOutletQuery("main", access_token)),
    queryClient.prefetchQuery(getNavOutletQuery("footer", access_token)),
    queryClient.prefetchQuery(getFrontPageQuery(access_token)),
  ]);

  return json({ dehydratedState: dehydrate(queryClient) });
}

export default function Index() {
  const { data: page } = useGetFrontPage();
  return (
    <FrontLayout>
      {page ? (
        <RenderComponentsView
          componentMap={componentMap}
          components={JSON.parse(page.content).components || []}
        />
      ) : (
        "error "
      )}
    </FrontLayout>
  );
}
